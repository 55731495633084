import React from "react";
import styled from "styled-components";

import {
  MainGrid,
  MainContent,
  Paragraph,
  Spacer,
  ImageWide,
} from "../../services/common";

import ProductCards from "../../services/components/productCard/ProductCards";

import Kinesiologia from "./kinesiologia.webp";
import Traumatologica from "./traumatologica.webp";
import Deportiva from "./deportiva.webp";
import Reumatologica from "./reumatologica.webp";
import Respiratoria from "./respiratoria.webp";
import Neurologica from "./neurologica.webp";

const items = [
  { img: Traumatologica, text: "Traumatológica" },
  { img: Deportiva, text: "Deportiva" },
  { img: Reumatologica, text: "Reumatológica" },
  { img: Respiratoria, text: "Respiratoria" },
  { img: Neurologica, text: "Neurológica" },
];
const config = {};

const KinesiologiaDomicilio = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Kinesiologia} />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
          <Paragraph>
            La Kinesiología es el estudio científico del movimiento humano,
            empleando los principios de las ciencias físicas, y en consecuencia,
            el estudio de la quinesiología o kinesiología abarca varias
            disciplinas.
          </Paragraph>
          <Paragraph>
            El entendimiento de la kinesiología es fundamental para el análisis
            y tratamiento de problemas en un principio al sistema neuromuscular
            esquelético, así como el sistema del cuerpo se ve afectado en su
            totalidad por afecciones que no afectan directamente a este. El
            kinesiólogo es especialista en la mejora, el mantenimiento de la
            capacidad fisiológica del individuo y la prevención de sus
            alteraciones o lesiones. Su formación le permite intervenir en y
            rehabilitación psicomotora, mediante la aplicación de técnicas y
            procedimientos de naturaleza física. No obstante, se analiza
            principalmente el movimiento humano (en todas sus ramificaciones)
            desde el punto de vista de las ciencias físicas.
          </Paragraph>
          <Paragraph>
            Por lo tanto, el interés principal de la Kinesiología es estudiar el
            comportamiento del movimiento en el ser humano.
          </Paragraph>
          <Paragraph>
            Los kinesiólogos a domicilio se desenvuelven formando parte de un
            equipo multidisciplinario de colaboración médica.
          </Paragraph>
          <Paragraph>
            Ofrecemos atención en forma programada asistiendo al paciente en
            todos los pasos necesarios a seguir para su mejor y más rápida
            recuperación.
          </Paragraph>
          <Paragraph>
            Los pedidos de atención de Lunes a Viernes de 9 a 19 hs.
          </Paragraph>
          <Paragraph>
            Una vez evaluada la solicitud, se deriva al profesional
            correspondiente, de acuerdo a la patología y edad del paciente.
            Dentro de las 24 hs. de recibido el pedido el profesional se
            comunicará con el paciente con el fin de coordinar el inicio del
            tratamiento.
          </Paragraph>
          <Paragraph>
            En caso de que el paciente no pueda ser contactado, el profesional
            concurrirá directamente sin previo aviso, dentro del período de
            tiempo indicado.
          </Paragraph>
          <Paragraph>
            Todo el sistema se haya activo los 365 días del año, sin excepción,
            cubriendo toda la Ciudad Autónoma de Buenos Aires y Gran Buenos
            Aires. Los casos que excedan dicho kilometraje serán considerados a
            efectos de poder ser asistidos.
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.red}>
        <MainContent noPadding="true">
          <Spacer height="40px" />
          <BoldTitle>Servicio de Rehabilitación</BoldTitle>
          <Spacer height="40px" />
          <ProductCards items={items} config={config} />
          <Spacer height="40px" />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

const BoldTitle = styled.div`
  font-size: 45px;
  font-family: "Arial Bold", sans-serif;
  font-weight: bold;
  color: white;
  opacity: 0.4;
  letter-spacing: 0.1em;
  text-align: center;
`;

export default KinesiologiaDomicilio;
