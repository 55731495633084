import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
} from "../../services/common";

import Auxiliar from "./auxiliar.webp";

const AuxiliarEnfermeria = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Auxiliar} />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
          <Paragraph>
            <Title>AUXILIAR DE ENFERMERIA LAS 24 HORAS</Title>
          </Paragraph>
          <Paragraph>
            El servicio de enfermería está recomendando en casos de pacientes
            que necesiten asistencia compleja y cuidados especiales,
            relacionados cuadros clínicos graves. Nuestro nuestro servicio de
            enfermería es realizado por profesionales matriculados, de
            experiencia, y puede cubrir los siguientes puntos:
            <ul>
              <li>Higiene y confort (baño en ducha o cama).</li>
              <li>Cambio de ropa de cama e indumentaria.</li>
              <li>Control de alimentación y preparación de alimentos.</li>
              <li>Acompañamiento en rehabilitación.</li>
              <li>Control de signos vitales.</li>
              <li>Acompañamiento a análisis o revisiones médicas.</li>
              <li>
                Mantenimiento, orden y limpieza de dormitorio, cocina y baño.
              </li>
            </ul>
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default AuxiliarEnfermeria;
