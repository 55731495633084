export default [
    { name: "Quienes Somos", type: "link", link: "quienes-somos.html" },
    { name: "Profesionales", type: "modal", link: "#profesionales", modalLinks: [
      {name: "Profesionales", link: "servicios-profesionales.html"},
      {name: "Médico Domiciliario", link: "medico-domiciliario.html"},
      {name: "Kinesiología a Domicilio", link: "kinesiologia-a-domicilio.html"},
      {name: "Masajista Profesional", link: "masajista-profesional.html"},
      {name: "Fonoaudiología", link: "fonoaudiologia.html"},
      {name: "Tratamientos Psicológicos", link: "tratamientos-psicologicos.html"},
      {name: "Terapia Ocupacional", link: "terapia-ocupacional.html"},
      {name: "Enfermería Profesional", link: "enfermeria-profesional.html"},
      {name: "Auxiliar de Enfermería", link: "auxiliar-enfermeria.html"},
      {name: "Acompañante Terapeutico", link: "acompanante-terapeutico.html"},
      {name: "Cuidadoras", link: "cuidadoras.html"},
    ] },
    { name: "Contratación", type: "link", link: "forma-de-contratacion.html" },
    { name: "Equipos / Alquiler y Venta", type: "modal", link: "#equipos-alquier-venta", modalLinks: [
      {name: "Equipos / Alquiler y Venta", link: "equipos-alquier-venta.html"},
      {name: "Camas ortopédicas", link: "camas-ortopedicas.html"},
      {name: "Sillas de ruedas", link: "sillas-ruedas.html"},
      {name: "Andadores", link: "andadores.html"},
      {name: "Muletas y Bastones", link: "muletas-bastones.html"},
      {name: "Nebulizadores", link: "nebulizadores.html"},
      {name: "Equipos de Oxígeno", link: "equipos-oxigeno.html"},
    ]},
    { name: "Traslados", type: "link", link: "traslados.html"},
    { name: "Personal Doméstico", type: "link", link: "personal-domestico.html"},
    { name: "Cuidado Personal", type: "link", link: "cuidado-personal.html"},
    { name: "Contáctenos", type: "link", link: "contactenos.html"},
    { name: "Sumate al equipo", type: "link", link: "sumate-al-equipo.html"},
  ];