import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
  TextWide,
} from "../../services/common";

import ImgPersonal from "./personal-domestico.webp";

const PersonalDomestico = () => {
  return (
    <>
          <ImageWide src={ImgPersonal} />
      <MainGrid backgroundColor={({ theme }) => theme.colors.blueBackground}>
        <MainContent>
          <TextWide fontSize="35px" fontStyle="normal">
            Para la higiene del paciente y de la familia conviviente
          </TextWide>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px"/>
          <Paragraph>
            <Title>Encuentre ayuda confiable en su hogar​</Title>
          </Paragraph>
          <Paragraph>
          Sabemos que tener un familiar enfermo en casa hace las tareas del hogar más complicadas, requiere mayor trabajo y al mismo tiempo la familia combina esto con su trabajo y rutina habitual.
          </Paragraph>
          <Paragraph>
          Por eso contamos con personal doméstico de confianza que puede realizar no solo la higiene de la habitación del paciente, sino también del domicilio en general.
          </Paragraph>
          <Paragraph>
          Cocina, baños, patios, habitaciones y salas de estar, así como patios y otros lugares de la casa.
          </Paragraph>
          <Paragraph>
          La higiene es importante para la persona en convalescencia, pero también para su familia, no la descuide, contrate personal adecuado, ya sea para una limpieza diaria o planificando semanal, quincenal o mensualmente la limpieza de su hogar. 
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default PersonalDomestico;
