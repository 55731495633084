import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
} from "../../services/common";

import ImgMasajistaProfesional from "./masajista-profesional.webp";

const MasajistaProfesional = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={ImgMasajistaProfesional} />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
          <Paragraph>
            <Title>Masaje Terapéutico Profesional</Title>
            <ul>
              <li>Masaje descontracturante y relajante.</li>
              <li>Anti estrés.</li>
              <li>Sedativo.</li>
              <li>Masaje circulatorio.</li>
              <li>Drenaje linfático manual.</li>
              <li>Reflexología</li>
              <li>Masajes reductores.</li>
              <li>Tratamientos de estética.</li>
              <li>Masoterapia para tratamiento del dolor y lesiones.</li>
              <li>Masajes deportivos.</li>
              <li>Tratamiento del insomnio.</li>
              <li>Dolores cervicales y lumbares.</li>
              <li>Várices y postquirúrgicos</li>
            </ul>
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">
              Masoterapeuta y Asistente Fisioterapeuta
            </Title>
            <ul>
              <li>
                Terapias y tratamientos dirigidos a personas con dolencias y/o
                afecciones referidas a malas posturas, ataques de pánico,
                lesiones. <br />
              </li>
              <li>Rehabilitación, elongación, magnetoterapia.</li>
              <li>
                Reflexología podal, manos y rostro. Drenaje linfático.
                Circulatorio.
              </li>
            </ul>
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default MasajistaProfesional;
