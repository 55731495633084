import React from "react";
import styled from "styled-components";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
} from "../../services/common";

const Fonoaudiologia = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
          <Paragraph>
            <Title>ASISTENCIA FONOAUDIOLÓGICA</Title>
          </Paragraph>
          <Paragraph>
            La Fonoaudiología tiene la particularidad de abordar desde distintas
            áreas del conocimiento el estudio de la comunicación humana y su
            problemática. La clínica fonoaudiológica nos permite realizar la
            evaluación y la terapéutica adecuadas a cada situación.
          </Paragraph>
          <Paragraph>
            La evaluación es un proceso que atraviesa diferentes instancias, en
            donde se considerará la trama comunicacional propia y particular de
            cada paciente. Tomamos en cuenta en el caso de los niños, aquellos
            indicadores que muestren la causa de las dificultades que afecten el
            desarrollo de la comunicación y el lenguaje, que se encuentran en
            evolución.
          </Paragraph>
          <Paragraph>
            Las múltiples manifestaciones en un mismo cuadro, hacen
            imprescindibles el análisis diferenciado y la comprensión de las
            mismas en la particularidad de cada niño y su familia.
          </Paragraph>
          <Paragraph>
            En la última etapa del proceso es posible pensar en un diagnóstico
            diferencial, que nunca es cerrado y definitivo, por tratarse de
            niños, pero que nos orientará para idear y ofrecer un diseño
            terapéutico adecuado a la realidad de los diferentes contextos en
            los que el niño interactúa.
          </Paragraph>
          <Paragraph>
            Teniendo en cuenta una mirada integral del paciente, se considerará
            el trabajo en equipo interdisciplinario en los casos que así lo
            requieran, derivando a consultas con otros profesionales de la
            Fundación Tiempo.
          </Paragraph>
          <Paragraph>
            La labor fonoaudiológica además de la tarea asistencial mencionada,
            se propone acercar a la comunidad la información pertinente.
            Recibiendo la consulta de los padres cuando tienen dudas acerca del
            lenguaje y la interacción comunicativa de sus hijos. El objetivo en
            esta instancia será prevenir y de esta forma favorecer la evolución
            infantil.
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor="#ffef98">
        <MainContent>
          <Spacer height="30px" />
          <Paragraph>
            <Nombre fontStyle="italic">
            	Lic. Karina Villalba
            </Nombre>
            <ul>
              <li>
                Lic. en Fonoaudiología. Universidad Nacional de Rosario (1998).
              </li>
              <li>
                Clínica asistencial en el ámbito público y privado en la ciudad
                de Rosario.
              </li>
              <li>
                Niños y adolescentes con dificultades en la interacción social y
                el lenguaje.
              </li>
              <li>Miembro de diferentes equipos interdisciplinarios.</li>
              <li>
                Formación específica en el área del lenguaje y el
                neurodesarrollo infantil (trastorno específico del lenguaje,
                TGD, Autismo).
              </li>
              <li>Miembro de la Fundación para la Tartamudez.</li>
              <li>
                Coordinadora del grupo de madres y padres de niños disfluentes,
                y de talleres de niños y padres.
              </li>
              <li>
                Co-autora del proyecto “Comunicación fluida de la emoción a la
                palabra”.
              </li>
              <li>Disertante en cursos y congresos sobre la tartamudez.</li>
              <li>
                Autora de artículos relacionados con la comunicación fluida y la
                familia.
              </li>
              <li>
                Fonoaudióloga de la Fundación Tiempo y de otros grupos
                interdisciplinarios de Buenos Aires.
              </li>
            </ul>
          </Paragraph>
          <Spacer height="30px"/>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px"/>
        </MainContent>
        </MainGrid>
    </>
  );
};


const Nombre = styled.span`
  font-family: 'Times New Roman', Times, serif;
  color: ${({theme})=>theme.colors.dark};
  font-style: italic;
  font-size: 40px;
  letter-spacing: 0.1em;
`;

export default Fonoaudiologia;
