import React from "react";
import styled from "styled-components";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
  TwoColumns
} from "../../services/common";

import CuidadosAbuela from "./cuidados-abuela.webp";
import CuidadosAbuelo from "./cuidados-abuelo.webp";

const Home = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={CuidadosAbuela} alt="" />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="30px" />
          <Paragraph>
            La unidad de Cuidados Domiciliarios o Internación Domiciliaria tiene
            como objetivo principal, y entre muchos otros beneficios, evitar las
            internaciones prolongadas en Clínicas, Sanatorios y Hospitales
            previniendo muchas complicaciones.
          </Paragraph>
          <Paragraph>
            Principales beneficios de la internación domiciliaria
            <ul>
              <li>
                Permite al paciente bene­ficiarse de la comodidad del hogar y de
                la compañía de su entorno familiar.
              </li>
              <li>
                Reduce el riesgo de contraer infecciones intrahospitalarias.
              </li>
              <li>
                Exime a la familia de tener que desplazarse al hospital y, al
                permitirles manejar sus tiempos, de noches de cuidados.
              </li>
              <li>
                Disminuye la ansiedad y el estrés que el hospital provoca a los
                pacientes.
              </li>
            </ul>
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
        <MainContent noPadding="true">
          <ImageWide src={CuidadosAbuelo} alt="" />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="30px" />
          <Paragraph>
            <Title>
              Algunos motivos por los que se puede acudir a una internación
              domiciliaria
            </Title>
            <ul>
              <li>
                Pacientes con insuficiencia respiratoria grave de causa
                neurológica central, espinal y/o muscular que requieran
                asistencia respiratoria mecánica sin potencial de recuperación
                de la función.
              </li>
              <li>
                Pacientes con secuelas graves de enfermedades neurológicas que
                requieran control y mantenimiento de funciones vitales, con y
                sin asistencia respiratoria mecánica.
              </li>
              <li>
                Enfermedades traumatológicas y/o de los músculos que por la
                complejidad de sus secuelas necesiten cuidados y asistencia
                médica y de enfermería intensivas en domicilio.
              </li>
              <li>
                Postquirúrgicos o pacientes con heridas abiertas hasta lograr la
                recuperación total de los tejidos.
              </li>
              <li>
                Enfermedades oncológicas en recuperación, en estadios terminales
                o en situación de cuidados paliativos.
              </li>
              <li>HIV en estadios terminales.</li>
              <li>Tratamiento del dolor.</li>
            </ul>
            Si lamentablemente el final de vida estuviera próximo, redoblamos
            nuestros esfuerzos en todo sentido; acompañamos en todo momento
            tanto al paciente como a Ud. y su familia, para hacer lo inevitable
            más llevadero.
          </Paragraph>
          <Paragraph>
            Ofrecemos asistencia activa y total por parte de un equipo
            interdisciplinario especializado tanto a los pacientes como a los
            familiares.
          </Paragraph>
          <Paragraph>
            <Title fontSize="22px">
              Estos cuidados tienen entre sus objetivos:
            </Title>
            <ul>
              <li>
                Controlar los síntomas y hacer más llevadero el proceso por el
                cual pasan los pacientes, su familia y entorno afectivo.
              </li>
              <li>
                Detectar, evaluar y paliar las necesidades físicas,
                psicológicas, sociales y espirituales.
              </li>
              <li>
                Promover e implementar estrategias de cuidado destinadas a
                proporcionar bienestar y mejorar la calidad de fin de vida.
              </li>
            </ul>
          </Paragraph>
          <Spacer height="20px"/>
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.dark}>
          <TextWide>
            “La ciencia evoluciona y redibuja los confines de la vida. ¿Tiene
            sentido prolongar la vida en estado vegetativo? ¿El testamento
            biológico podría ser una solución? No soy un especialista en
            argumentos bioéticos y temo equivocarme en mis palabras. La doctrina
            tradicional de la Iglesia dice que nadie está obligado a usar
            métodos extraordinarios cuando alguien está en su fase terminal.
            Pastoralmente, en estos casos yo siempre he aconsejado los cuidados
            paliativos. En casos más específicos, de ser necesario, conviene
            recurrir al consejo de los especialistas.” ​<br />
            <br />
            Francisco. <br />
            (Sumo Pontífice de la Iglesia Católica)
          </TextWide>
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Paragraph>
            <Title>Testimonios</Title>
          </Paragraph>
          <TwoColumns>
            <Testimony>
              <p>
                Un día mi familia pasó por una situación difícil. Con el trabajo
                de cada uno y nuestro padre enfermo los tiempos se complicaban
                cada vez más. Es por eso que decidimos recurrir a internación
                domiciliaria.
              </p>
              <p>
                Quedamos muy conformes con los servicios contratados ya que, la
                persona que cuidaba a mi padre sabía perfectamente su trabajo,
                ayudándolo a recuperarse mediante métodos profesionales.
              </p>
              <p>
                Estamos muy contentos con la atención brindada por
                internacióndomiciliaria
              </p>
              <Name>Carlos Ríos Palermo</Name>
            </Testimony>
            <Testimony>
              <p>
                Ana Juárez estuvo junto a mi madre 3 años. Ella necesitaba un
                vendaje especial en las piernas que debía ser reemplazado y
                desinfectado periódicamente, además de controlar el azúcar en su
                sangre y administrarle los medicamentos para la diabetes y el
                Alzheimer.
              </p>
              <p>
                Al poco tiempo de tenerla con nosotros, mi madre sanó de las
                piernas y la diabetes fue controlada, mediante el cuidado de
                profesional que la acompañante de internación domiciliaria nos
                brindó
              </p>
              <Name>Alejandra Peralta</Name>
            </Testimony>
          </TwoColumns>
          <Spacer height="30px"/>
        </MainContent>
        </MainGrid>
        <MainGrid backgroundColor={({ theme }) => theme.colors.dark}>
        <MainContent>
          <TextHero>
            <p>Tipos de servicio integral</p>
            <ul>
              <li>Internacion domiciliaria para adultos</li>
              <li>Internación domiciliaria pediátrica</li>
              <li>Internación domiciliaria gerontológica</li>
              <li>Cuidados paliativos en domicilio particular</li>
              <li>Cuidados paliativos en centro asistencial</li>
            </ul>
          </TextHero>
        </MainContent>
      </MainGrid>
        <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="30px"/>
        </MainContent>
      </MainGrid>
    </>
  );
};

const TextWide = styled.p`
margin: 30px 20px;
  color: ${({ theme }) => theme.colors.bright};
  font-family: "Lora", serif;
  font-size: 18px;
  line-height: 1.8em;
  letter-spacing: 0.1em;
`;

const Testimony = styled.div`
  font-style: italic;
  color: ${({ theme }) => theme.colors.primary};

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    padding: 15px;
  }
  p:first-of-type:before {
    content: "“";
    font-size: 30px;
    font-family: "Lora", serif;
  }

  p:last-of-type:after {
    content: "”";
    font-size: 30px;
    font-family: "Lora", serif;
  }
`;

const Name = styled.div`
  text-align: right;
  font-weight: bold;
  font-size: 18px;
  font-style: italic;
`;

const TextHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto", sans-serif;
  color: ${({ theme }) => theme.colors.bright};
  letter-spacing: 0.15em;

  p {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    opacity: 0.9;
  }

  li {
    margin-bottom: 20px;
  }
`;

export default Home;
