import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
  TextWide,
  TwoColumns,
  CircleImage,
} from "../../services/common";

import Pedicura from "./pedicura.webp";
import Peluqueria from "./peluqueria.webp";
import Manicura from "./manicura.webp";

const CuidadoPersonal = () => {
  return (
    <>
      <ImageWide src={Pedicura} />
      <MainGrid backgroundColor={({ theme }) => theme.colors.blueBackground}>
        <MainContent>
          <TextWide fontSize="35px" fontStyle="normal">
            Cuidar la apariencia ayuda a mejorar el ánimo del paciente, por eso
            ofrecemos servicios a domicilio con personal capacitado
          </TextWide>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
          <Paragraph>
            <Title>Peluquería​</Title>
          </Paragraph>
          <TwoColumns desktopGridTemplate="6fr 2fr">
            <div>
              <Paragraph>
                Durante el periodo de caída del cabello es importante hallar
                soluciones que nos ayuden a seguir adelante sin sufrir cambios
                traumáticos en nuestra imagen. No existe una recomendación
                estándar en cuestiones estéticas, por eso adaptamos la peluca a
                las necesidades de cada mujer, tanto en composición como en
                estilo y precio.
              </Paragraph>
              <Paragraph>
                Contamos con especialistas que le ayudarán a elegir la peluca
                más adecuada, de pelo natural o fibra tratada; asimismo, le
                asesorarán en otras soluciones capilares.
              </Paragraph>
              <Paragraph>
                Todos sabemos que los tratamientos oncológicos producen efectos
                secundarios, pueden afectar al cabello, a la piel y al aspecto
                físico en general, muy importantes para el bienestar de la
                persona. Para esos casos, en kaina Villalba contamos con
                profesionales que le ofrecerán un excelente asesoramiento en
                pelucas y postizos a medida para todo tipo de problemas
                oncológicos.
              </Paragraph>
              <Paragraph>
                No dude en hacer sus consultas, recibirá atención personalizada.
              </Paragraph>
            </div>
            <CircleImage src={Peluqueria} diameter="150px" />
          </TwoColumns>
          <Paragraph>
            <Title>Manicura y Pedicura</Title>
          </Paragraph>
          <TwoColumns desktopGridTemplate="6fr 2fr">
            <div>
              <Paragraph>
                Un buen cuidado de la manos y los pies es algo fundamental en la
                imagen de una persona y, en ocasiones, nos resulta complicado
                cuidar nuestras uñas, tenerlas uniformes...
              </Paragraph>
              <Paragraph>
                Por ello, realizamos la manicura y pedicura para que sus manos y
                sus pies luzcan perfectos
              </Paragraph>
            </div>
            <CircleImage src={Manicura} diameter="150px" />
          </TwoColumns>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default CuidadoPersonal;
