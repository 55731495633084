import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
} from "../../services/common";

import Acompañante from "./acompañante.webp";

const AcompañanteTerapeutico = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Acompañante} />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
          <Paragraph>
            <Title>Acompañante terapéutico</Title>
          </Paragraph>
          <Paragraph>
            El acompañante es aquel que acompaña (que brinda su compañía o
            apoyo). Terapéutico, por su parte, es lo vinculado al tratamiento de
            una afección, una enfermedad o un malestar.
          </Paragraph>
          <Paragraph>
            Un acompañante terapéutico es un profesional que actúa como auxiliar
            de salud, colaborando con una persona que está bajo tratamiento
            médico. Estos acompañantes pueden asistir de diferentes maneras a
            las personas que padecen autismo, esquizofrenia, psicosis,
            trastornos de desarrollo y otros inconvenientes.
          </Paragraph>
          <Paragraph>
            El rol del acompañante terapéutico varía con cada paciente. A nivel
            general, puede decirse que el acompañante contiene al sujeto y lo
            asiste de distintas formas. En algunos casos, colabora con el
            paciente para que pueda realizar ciertas actividades cotidianas que,
            en solitario, no podría llevar a cabo.
          </Paragraph>
          <Paragraph>
            También es usual que el acompañante terapéutico impulse al paciente
            a continuar con su tratamiento, sobre todo cuando existen problemas
            de tipo psicológico que dificultan el avance de la terapia. El
            profesional debe estar capacitado para ayudar a superar las barreras
            emocionales.
          </Paragraph>
          <Paragraph>
            Ante un trastorno de tipo invalidante, como ser falta de movilidad
            de gran parte del cuerpo a causa de un accidente, es muy común que
            los pacientes se muestren reacios a llevar a cabo los ejercicios que
            les indican sus médicos. Si pensamos en el caso de un deportista,
            por ejemplo, que de pronto queda postrado en una silla de ruedas y
            se ve privado de sus actividades físicas, las cuales solía realizar
            a diario motivado por su vocación, no es difícil imaginar el impacto
            negativo que este cambio puede provocar en su ánimo.
          </Paragraph>
          <Paragraph>
            Para luchar contra ésta y otras barreras existe el rol del
            acompañante terapéutico, quien debe encontrar los vestigios de
            fuerza de voluntad que quedan en sus pacientes, y construir a partir
            de ellos una gran fuente de energía para salir adelante. Así como en
            cualquier otra relación profesional en la cual la psicología sea una
            parte fundamental, el acompañante debe acercarse a su paciente,
            llegar a conocerlo, sentir empatía por él, para recién entonces
            encontrar la mejor manera de motivarlo a cumplir con su tratamiento.
          </Paragraph>
          <Paragraph>
            Otra de las funciones del acompañante terapéutico es potenciar las
            relaciones sociales del paciente. Para esto puede impulsarlo a
            participar de juegos, propuestas lúdicas o incluso tareas laborales,
            brindándole su compañía en este marco.
          </Paragraph>
          <Paragraph>
            Una de las consecuencias más frecuentes de las enfermedades y los
            trastornos que requieren la ayuda de un acompañante terapéutico es
            el aislamiento y el rechazo de cualquier tipo de ayuda por parte de
            amigos y familiares. Es tarea del profesional hallar el modo de
            disipar el mal humor y el desgano, para desbloquear al paciente e
            incentivarlo a que se acerque nuevamente a sus seres queridos, e
            incluso a que entable nuevos lazos.
          </Paragraph>
          <Paragraph>
            Puede decirse, en definitiva, que el acompañante terapéutico es el
            sostén del paciente mientras desarrolla su tratamiento. El objetivo
            es que el individuo que recibe el acompañamiento pueda mejorar su
            condición y adquirir toda la autonomía posible, minimizando las
            limitaciones y explotando las capacidades adquiridas. Así, una vez
            que finaliza el tratamiento, la persona debe contar con más recursos
            para desarrollar su vida.
          </Paragraph>
          <Paragraph>
            Hay que recordar que no todos los tratamientos conducen a la total
            recuperación; por el contrario, muchos de ellos simplemente apuntan
            a mejorar la calidad de vida o a superar ciertos síntomas. Por esta
            razón, el acompañante terapéutico debe preparar a sus pacientes para
            que aprendan a llevar adelante una vida plena y gratificante a pesar
            de sus potenciales limitaciones, en lugar de prometerles una
            curación que quizás nunca llegue. Es muy importante conseguir que
            las personas recuperen las ganas de vivir y que no se vuelvan
            dependientes de sus acompañantes, y éstos son dos objetivos muy
            difíciles de alcanzar.
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default AcompañanteTerapeutico;
