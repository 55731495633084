export default {
    colors: {
        primary: '#868686',
        menuBackground: '#f3f3f3',
        defaultBackground: '#868686',
        blueBackground: '#87b1cb',
        bright: 'white',
        dark: '#252a04',
        red: '#ff6161',
        green: '#7bbe63',
        lightGreen: "#66cf5b",
    },
    fonts: {
        titlesBig: '"Helvetica", sans-serif',
        paragraphs: '"Helvetica", sans-serif',
    },
    mediaQueries: {
        'desktop': `only screen and (min-width: 900px)`
    }
};