import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
} from "../../services/common";

import Terapias from "./terapias.webp";

const TerapiaOcupacional = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Terapias} />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
          <Paragraph>
            <Title>Terapia Ocupacional</Title>
          </Paragraph>
          <Paragraph>
            El Profesional en Terapia Ocupacional, debe tener una fundamentación
            científica, dinámica y permanente: que le permita desempeñarse en
            áreas de: Salud, Educación, Justicia Social y laboral, con habilidad
            para identificar problemas que afectan a los usuarios en relación a
            la tarea, a la persona o al ambiente así como ser asertivo en la
            aplicación de marcos de referencia y enfoques.
          </Paragraph>
          <Paragraph>
            En el sector de la salud, el terapeuta ocupacional interviene en
            disfunciones físicas, mentales, motoras sensoriales, perceptuales y
            sociales, además de participar en programas de prevención de la
            disfunción y promoción de la salud, buscando el desempeño del rol
            ocupacional, de acuerdo a la etapa de la vida en que el individuo se
            encuentra. Así mismo, incursiona en el sector educativo (Educación
            formal y no formal), realizando asistencia, detección, prevención de
            disfunción que afecte el desempeño escolar y asesorías,
            consultorías, planeación de programas, planes y proyectos educativos
            en sus diferentes etapas de desarrollo.
          </Paragraph>
          <Paragraph>
            El Terapeuta Ocupacional, en el sector laboral, incursiona en forma
            planeada y coordinada en diferentes procesos involucrados en la
            consecución del bienestar del ser humano en torno al desarrollo de
            su ocupación, el logro de su independencia y productividad.
            Simultáneamente desarrolla programas de bienestar social del
            trabajador y de su familia.
          </Paragraph>
          <Paragraph>
            Promueve la participación activa del trabajador a su integración
            laboral, familiar y social. Participa en el control de las
            condiciones de salud en sitios de trabajo, previniendo riesgos y
            adecuando el nivel de funcionalidad de cada individuo. Uno de los
            campos en los que se desempeña el terapeuta ocupacional dentro del
            área de la industria, es el de la rehabilitación profesional, que se
            realiza a través del desarrollo de etapas. El éxito de su aplicación
            está dado por cuanto logre que la persona conserve y progrese en un
            empleo adecuado, y se cumpla el objetivo final que es la integración
            de la persona a la sociedad.
          </Paragraph>
          <Paragraph>
            En el sector justicia, el Terapeuta Ocupacional interviene, planea,
            desarrolla y asesora la planeación de programas de prevención,
            orientación vocacional y rehabilitación laboral que van dirigidos al
            menor abandonado, desprotegido, y con riesgo físico y/o moral, y al
            adulto penalizado.
          </Paragraph>
          <Paragraph>
            Los terapeutas ocupacionales son profesionales socio sanitarios que
            dirigen su actuación a personas que presentan una discapacidad, a
            mayores, recién nacidos, niños en edad escolar y cualquiera que haya
            estado, de modo permanente o temporal, impedido en sus funciones
            físicas o mentales o que éstas se vean en riesgo.
            <ul>
              <li>
                <p>Tercera edad y terapia ocupacional</p>
                Al ir disminuyendo las habilidades como parte del proceso de
                envejecimiento, suelen hacerse necesarias las ayudas
                asistenciales o los cambios que faciliten una vida con cierta
                independencia y seguridad en el propio hogar. Un terapeuta
                ocupacional trabaja con la familia y el afectado para ajustarse
                a sus necesidades, habilidades.
              </li>
              <li>
                <p>La terapia ocupacional en lo laboral</p>
                Después de haber sufrido un accidente o incidencia con baja
                laboral y pasado el tiempo adecuado de rehabilitación se produce
                un periodo de transición antes de volver al trabajo que debe
                tenerse en cuenta y es muy importante para conseguir una
                integración al mismo con todas las capacidades, a pleno
                rendimiento y total seguridad.
              </li>
              <li>
                <p>
                  EL TERAPEUTA OCUPACIONAL SE DESEMPEÑA EN LOS SIGUIENTES CAMPOS
                </p>
                <p>
                  <b>SALUD:</b> Interviene a las personas (niños, adultos,
                  ancianos) que han sufrido accidentes o enfermedades que le
                  limitan la forma normal de desenvolverse independientemente en
                  las actividades básicas cotidianas, requieren rehabilitación y
                  tratamiento.
                </p>
                <p>
                  EN DONDE?: Hospitales, Clínicas, IPS, Hospitales
                  Psiquiátricos, Centros de Reposo y Consultorios Particulares.
                </p>
                <p>
                  <b>EDUCACIÓN:</b> Trabajo con niños especiales, que necesitan
                  apoyo y seguimiento para ser independientes y funcionales para
                  si mismos, la familia y la sociedad; y con niños normales que
                  tienen problemas de aprendizaje.
                </p>
                <p>
                  EN DONDE?: Colegios Regulares, Jardines Infantiles, Centros de
                  Estimulación, Colegios e Instituciones Especiales,
                  Consultorios Particulares.
                </p>
                <p>
                  <b>TRABAJO:</b> Interviene en las empresas orientando procesos
                  de reintegro a la actividad laboral de trabajadores que han
                  sufrido accidentes y en actividades de salud ocupacional.
                </p>
                <p>DONDE? En todo tipo de empresa</p>
                <p>
                  <b>COMUNIDAD:</b> Se trabaja con comunidades desplazadas o
                  privadas de la libertad promoviendo actividades de
                  esparcimiento, prevención y laborales, que les permita
                  reincorporarse activa y funcionalmente a la sociedad.
                </p>
                <p>
                  EN DONDE?: Cárceles, Instituciones de Rehabilitación de Niños
                  de la Calle, Instituciones de Rehabilitación de
                  Farmacodependientes, proyectos de Alcaldías y Departamentos.
                </p>
                LABORATORIOS
                <ul>
                  <li>Laboratorio de Férulas y Equipo Especial</li>
                  <li>Laboratorio de Juego</li>
                  <li>
                    Laboratorio de Actividades de la Vida Diaria y
                    Rehabilitación (Entrenamiento Funcional)
                  </li>
                  <li>Laboratorio de Anfiteatro</li>
                  <li>Laboratorio de Física</li>
                </ul>
              </li>
            </ul>
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default TerapiaOcupacional;
