import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./index.css";

import Header from "./sections/header/Header";
import Menu from "./sections/menu/Menu";
import Footer from "./sections/footer/Footer";

import Home from "./pages/home/Home";
import QuienesSomos from "./pages/quienes-somos/QuienesSomos";
import Contratacion from "./pages/contratacion/Contratacion";
import Profesionales from "./pages/profesionales/Profesionales";
import MedicoDomiciliario from "./pages/medico-domiciliario/MedicoDomiciliario";
import KinesiologiaDomicilio from "./pages/kinesiologia-a-domicilio/KinesiologiaDomicilio";
import MasajistaProfesional from "./pages/masajista-profesional/MasajistaProfesional";
import Fonoaudiologia from "./pages/fonoaudiologia/Fonoaudiologia";
import TratamientosPsicologicos from "./pages/tratamientos-psicologicos/TratamientosPsicologicos";
import TerapiaOcupacional from "./pages/terapia-ocupacional/TerapiaOcupacional";
import EnfermeriaProfesional from "./pages/enfermeria-profesional/EnfermeriaProfesional";
import AuxiliarEnfermeria from "./pages/auxiliar-enfermeria/AuxiliarEnfermeria";
import AcompañanteTerapeutico from "./pages/acompañante-terapeutico/AcompañanteTerapeutico";
import Cuidadoras from "./pages/cuidadoras/Cuidadoras";
import Traslados from "./pages/traslados/Traslados";
import PersonalDomestico from "./pages/personal-domestico/PersonalDomestico";
import CuidadoPersonal from "./pages/cuidado-personal/CuidadoPersonal";
import Contactenos from "./pages/contactenos/Contactenos";
import EquiposAlquilerVenta from "./pages/equipos-alquiler-venta/EquiposAlquilerVenta";
import CamasOrtopedicas from "./pages/camas-ortopedicas/CamasOrtopedicas";
import SillasRuedas from "./pages/sillas-ruedas/SillasRuedas";
import Andadores from "./pages/andadores/Andadores";
import MuletasBastones from "./pages/muletas-bastones/MuletasBastones";
import Nebulizadores from "./pages/nebulizadores/Nebulizadores";
import EquiposOxigeno from "./pages/equipos-oxigeno/EquiposOxigeno";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Menu />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/quienes-somos.html" component={QuienesSomos}/>
          <Route path="/forma-de-contratacion.html" component={Contratacion}/>
          <Route path="/servicios-profesionales.html" component={Profesionales}/>
          <Route path="/medico-domiciliario.html" component={MedicoDomiciliario}/>
          <Route path="/kinesiologia-a-domicilio.html" component={KinesiologiaDomicilio}/>
          <Route path="/masajista-profesional.html" component={MasajistaProfesional}/>
          <Route path="/fonoaudiologia.html" component={Fonoaudiologia}/>
          <Route path="/tratamientos-psicologicos.html" component={TratamientosPsicologicos}/>
          <Route path="/terapia-ocupacional.html" component={TerapiaOcupacional}/>
          <Route path="/enfermeria-profesional.html" component={EnfermeriaProfesional}/>
          <Route path="/auxiliar-enfermeria.html" component={AuxiliarEnfermeria}/>
          <Route path="/acompanante-terapeutico.html" component={AcompañanteTerapeutico}/>
          <Route path="/cuidadoras.html" component={Cuidadoras}/>
          <Route path="/traslados.html" component={Traslados}/>
          <Route path="/personal-domestico.html" component={PersonalDomestico}/>
          <Route path="/cuidado-personal.html" component={CuidadoPersonal}/>
          <Route path="/contactenos.html" component={Contactenos}/>
          <Route path="/equipos-alquier-venta.html" component={EquiposAlquilerVenta}/>
          <Route path="/camas-ortopedicas.html" component={CamasOrtopedicas}/>
          <Route path="/sillas-ruedas.html" component={SillasRuedas}/>
          <Route path="/andadores.html" component={Andadores}/>
          <Route path="/muletas-bastones.html" component={MuletasBastones}/>
          <Route path="/nebulizadores.html" component={Nebulizadores}/>
          <Route path="/equipos-oxigeno.html" component={EquiposOxigeno}/>

        </Switch>
        <Footer />

      </div>
    </BrowserRouter>
  );
}

export default App;
