import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
  TwoColumns,
  CircleImage
} from "../../services/common";

import ImgCuidadoras1 from "./cuidadoras.webp";
import ImgCuidadoras2 from "./cuidadoras2.webp";

const Cuidadoras = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={ImgCuidadoras1} />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
          <Paragraph>
            <Title>
              Cuidadoras las 24 horas, a domicilio, en sanatorios, clínicas,
              hospitales y geriátricos.
            </Title>
          </Paragraph>
          <Paragraph>
            Nuestro servicio de cuidadores está ideado para brindar asistencia,
            cuidado y acompañamiento a adultos mayores que no necesiten
            asistencia médica compleja y no presenten cuadros clínicos graves.
            El personal, especialmente elegido para la tarea, de experiencia y
            responsable, puede cubrir los siguientes puntos.
            <ul>
              <li>Higiene y confort (baño en ducha o cama).</li>
              <li>Cambio de ropa de cama e indumentaria.</li>
              <li>
                Control de alimentación. Preparan comidas especiales según la
                dieta que tenga el paciente.
              </li>
              <li>Acompañamiento en rehabilitación.</li>
              <li>Control de signo vital.</li>
              <li>Acompañamiento a análisis o revisiones médicas.</li>
              <li>
                Mantenimiento, orden y limpieza de dormitorio, cocina y baño de
                la paciente
              </li>
            </ul>
            Nuestro servicio de cuidadores puede ser contratado en módulos de
            4,6, 8, 10,12 y 24 horas, y también puede ser eventual o mensual.
          </Paragraph>
          <Paragraph>
            <Title>Internación domiciliaria</Title>
          </Paragraph>
          <Paragraph>
            Las 24 horas 365 días del año <br />
            Cuidando abuelos y niños de todo tipo de patologías con
            profesionales matriculados. <br />
            Capacitados por INTERNACION DOMICILIARIA , trabajamos en domicilio
            sanatorios, hospitales, geriátricos, clínicas .CABA Y GBA .
          </Paragraph>
          <Paragraph>Profesionales en la salud cuidando:</Paragraph>
          <TwoColumns>
            <Paragraph>
              <ul>
                <li>Pacientes para acompañar para su seguridad.</li>
                <li>Pacientes con demencia senil</li>
                <li>Pacientes con párkinson,</li>
                <li>Pacientes con parálisis cerebral</li>
                <li>Pacientes con embolia pulmonar</li>
                <li>Pacientes con esquizofrenia</li>
                <li>Pacientes con diabetes</li>
                <li>Pacientes con cáncer (colon, hígado, páncreas y otros)</li>
                <li>Pacientes con acv</li>
                <li>Pacientes con operaciones de cadera y otras operaciones</li>
                <li>Pacientes psiquiátricos</li>
                <li>Pacientes con epilepsia y retraso madurativo</li>
                <li>Pacientes con retraso madurativo</li>
                <li>Pacientes con otras patologías ..</li>
              </ul>
            </Paragraph>
            <CircleImage src={ImgCuidadoras2} />
          </TwoColumns>
          <Paragraph>
            LO QUE REALIZAN NUESTROS PROFESIONALES EN DOMICILIO
            <ul>
              <li>Traslado de la cama a la silla de ruedas</li>
              <li>
                Ayudar en su rehabilitación caminar ,movimiento de piernas
                ,brazos .otros
              </li>
              <li>
                Higiene confort en la cama o en la ducha según el estado que se
                encuentra el paciente
              </li>
              <li>Cambio de pañales</li>
              <li>LAS CUIDADORAS PREPARAN LOS ALIMENTOS</li>
              <li>Dar los remedios siempre con la autorización de su medico</li>
              <li>
                Preparación de los alimentos siempre en cuando no se encuentre
                la familia
              </li>
              <li>Mantener limpio el lugar donde se encuentra el paciente</li>
              <li>Realizar curaciones de ulceras y escaras y otros</li>
              <li>
                Cambio de sabanas y mantener en orden sus prendas de la paciente
              </li>
              <li>Ayudar a vestirle a la paciente</li>
              <li>
                Salidas a la calle con la silla de ruedas siempre con la
                autorización de la familia
              </li>
              <li>
                Ir a sus tratamientos acompañados de la cuidadora o turno con
                sus médicos
              </li>
              <li>Control de la sonda vesical</li>
              <li>Botón gástrico</li>
              <li>Aspiraciones</li>
              <li>Nebulizar</li>
              <li>Control de los signos vitales</li>
            </ul>
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
    </>
  );
};


export default Cuidadoras;
