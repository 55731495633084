import React from "react";
import { render } from "react-snapshot";
// import ReactDOM from 'react-dom';
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { ThemeProvider } from "styled-components";
import Theme from "./services/theme";

//previously ReactDOM.render(
render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
