import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
  TextWide,
} from "../../services/common";

import Abuelo from "./abuelo.webp";

const QuienesSomos = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Abuelo} />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.blueBackground}>
        <MainContent >
          <TextWide fontSize="25px">
          Una organización que está allí -a veces durante los momentos más complicados- como un aliado, para garantizar los debidos cuidados clínicos -profesionales- y el acompañamiento del paciente al tiempo de la debida tranquilidad para sus familiares y amigos.
          </TextWide>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Paragraph>
            <Title fontSize="18px">
            Internación domiciliaria  brinda soluciones a aquellas personas que no disponen de tiempo, movilidad y dedicación para realizarlo, usted sólo elíjanos y su familia y/o empresa se sentirá en manos confiables y expertas que cuidaran de sus seres queridos y empleados.
            </Title>
            </Paragraph>
            <Paragraph>
            La metodología aplicada en los servicios se encuentra particularmente estudiada, documentada, estandarizada y por tanto optimizada (organizamos regularmente jornadas de perfeccionamiento con el objetivo de avanzar hacia la calidad total) en toda la zona de cobertura, de manera que ante -por ejemplo- el eventual cambio de un profesional (sea por rotación horaria, licencias...) Ud. no perciba ninguna diferencia.
            </Paragraph>
            <Paragraph>
            <b>Opciones de Planes</b>
            <ul>
            <li>Abono semanal / quincenal /mensual / anual</li>
            <li>Tarifación por turno</li>
            <li>Tarifación por hora</li>
            <li>Seguro de atención</li>
            <li>Sistema prepago por cuota mensual individual</li>
            <li>Sistema prepago por vouchers transferibles y no transferibles</li>
            <li>Planes integrales de atención</li>
            </ul>
            Los planes integrales prepagos de internación domiciliaria  ofrecen soluciones personalizadas a nuestros pacientes. Los especialistas en cuidados especiales se encargan de la nutrición, administración de medicamentos con prescripción y diversas tareas de higiene. Este servicio es brindado de 24, 16, 12, 10, 8, 6,4 horas abonados mensualmente.
          </Paragraph>
          <Paragraph>
            <Title fontWeight="bold" fontSize="18px">
              Venta de horas
            </Title>
            <br />
            Este sistema permite a la familia y/o responsables de los pacientes
            diagramar libremente los días y horarios según su necesidad y/
            comodidad. Internación domiciliaria adapta su cobertura de acuerdo a
            cada situación, enviando un profesional donde lo requiera:
            sanatorio, clínica, residencia geriátrica, domicilio, etc. *El
            servicio se contrata por un mínimo de 6 horas. Asociase a nuestro
            sistema internación domiciliaria de servicios.
          </Paragraph>
          <Paragraph>
            Gracias a nuestro plan de servicios GOLD, usted podrá acceder a
            nuestra amplia cobertura en servicios de salud abonando una cuota
            mensual.
          </Paragraph>
          
          <Spacer height="20px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default QuienesSomos;
