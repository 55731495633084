import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
  TextWide,
} from "../../services/common";

import ImgProfesionales from "./profesionales.webp";

const Profesionales = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={ImgProfesionales} />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.blueBackground}>
        <MainContent>
          <TextWide fontSize="45px" fontStyle="normal">
            Con amor y comprensión, cuidamos a sus seres queridos.
          </TextWide>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Paragraph>
            <ul>
              <li>Cuidadoras de abuelos</li>
              <li>Cuidadoras de niños</li>
              <li>Acompañantes Terapéuticos</li>
              <li>Acompañantes Gerontológicos</li>
              <li>Auxiliares en Enfermería</li>
              <li>Enfermeros Profesionales</li>
              <li>Licenciados en Enfermería</li>
              <li>Médico Clínico a Domicilio</li>
              <li>Médico Especialista a Domicilio</li>
              <li>Psicólogos</li>
              <li>Psiquiatras</li>
              <li>Kinesiólogos</li>
              <li>Fonoaudiólogos</li>
              <li>Peluquería</li>
              <li>Pedicura</li>
              <li>Manicura</li>
              <li>Traslados programados</li>
              <li>Monitoreo EN VIVO de cada paciente (*)</li>
              <li>Coordinación pormenorizada de servicios</li>
              <li>Asesoramiento y gestión administrativa ante deceso</li>
              <li>Asesoramiento legal</li>
            </ul>
            (*) Método y sistema propietario registrado por ..INTERNACION
            DOMICILIARIA <br />
            (**) Servicio integral de atención clínica, administrativa,
            financiera y legal.
          </Paragraph>
          <Paragraph>
            <Title>
              Contamos con distintos planes, para que pueda tomar aquel que se
              ajuste a sus necesidades
            </Title>
            <ul>
              <li>Abono por Servicio Horario</li>
              <li>Abono por Servicio Diario</li>
              <li>Abono por Servicio Semanal</li>
              <li>Abono por Servicio Mensual</li>
            </ul>
          </Paragraph>
          <Spacer height="30px"/>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default Profesionales;
