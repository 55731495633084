import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
  TextWide,
} from "../../services/common";

import ImgTraslados from "./traslados.webp";
import Ambulancia from "./ambulancia.webp";

const Traslados = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={ImgTraslados} />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.blueBackground}>
        <MainContent>
          <TextWide fontSize="35px" fontStyle="normal">
            Traslados, Hospitales, Sanatorios, Clínicas, Domicilio, Geriátricos.
            Personal altamente capacitado y Ambulancias según su necesidad.
          </TextWide>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Paragraph>
            Nuestro objetivo es brindar servicios pre hospitalario de excelencia
            en toda la Ciudad de Buenos Aires, Gran Buenos Aires y alrededores.
          </Paragraph>
          <Paragraph>
            Contamos con una amplia trayectoria, con estructura y móviles
            propios atendiendo miles de servicios cada año. <br />
            Nuestros principales servicios son ambulancias para: eventos,
            traslados, emergencias y urgencias.
          </Paragraph>
          <Paragraph>
            Tenemos una moderna flota de ambulancias de alta, mediana y baja
            complejidad, todas carrozadas como UTIM, preparadas para atender las
            necesidades particulares de cada servicio que ofrecemos.
          </Paragraph>
          <Paragraph>
            Además están equipadas con aire acondicionado, calefacción y rastreo
            satelital permanente para su mayor confort y seguridad. <br />
            Las mismas se encuentran habilitadas por el ministerio de salud y
            cuentan con VTV al día.
          </Paragraph>
          <Spacer height="10px"/>
          <Paragraph>
            <Title>AMBULANCIAS DE ALTA COMPLEJIDAD</Title>
            <br />
            <Title fontSize="20px">Traslados UTIM y Emergencias.</Title>
            <br/>
            Se encuentran equipadas para el traslado de pacientes que requieran
            soporte de sus funciones vitales o presenten inestabilidad clínica
            manifiesta.
          </Paragraph>
          <Paragraph>
            Cuentan con médico y conductor/paramédico capacitados en
            emergentología.
          </Paragraph>
          <Spacer height="10px"/>
          <Paragraph>
            <Title>AMBULANCIAS DE MEDIANA COMPLEJIDAD</Title>
            <br />
            <Title fontSize="20px">Traslados con Médico.</Title>
            <br />
            Los móviles se encuentran preparados y con el equipamiento
            necesario, para cubrir traslados con asistencia médica a bordo.
            <br />
            La dotación está conformada por médico y conductor/paramédico.
          </Paragraph>
          <Spacer height="10px"/>
          <Paragraph>
            <Title>AMBULANCIAS DE BAJA COMPLEJIDAD</Title>
            <br />
            <Title fontSize="20px">Traslados Comunes.</Title>
            <br />
            Las ambulancias están equipadas para el traslado de pacientes de
            bajo riesgo y que no requieren de un médico para su asistencia.{" "}
            <br />
            La dotación está conformada por conductor y camillero.
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">Equipamiento</Title>
          </Paragraph>
          <Paragraph>
            Desfibrilador portátil, electrocardiógrafo portátil, saturometro
            digital, camilla, silla de ruedas, respirador, aspirador, kit de vía
            aérea, oxigeno central y manual, kit de trauma, kit de paro con set
            de RCP, collares ortopédicos, maletines con todos los medicamentos y
            descartables necesarios para cubrir cualquier tipo de atención.
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Ambulancia} />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Paragraph>
            RENOVAMOS A DIARIO NUESTRO COMPROMISO DE CUIDARTE <br />
            <Title>Nos importa y estamos con vos.</Title>
          </Paragraph>
          <Paragraph>
            Nos dedicamos a traslados programados, comunes o de alta complejidad
            (UTIM), locales o al interior del país. Contamos con personal,
            móviles y equipamiento de primera línea. Experiencia, seriedad y
            responsabilidad, forman las bases y la visión de nuestra empresa.
          </Paragraph>
          <Paragraph>
            La calidad y la excelencia de nuestro servicio están exentos de
            riesgos legales. Nuestras unidades poseen cobertura de seguros,
            personas transportadas y pacientes, también los médicos poseen
            seguro de mala praxis. <br />
            Años de trayectoria nos avalan <br />
            Excelencia en Móviles y Equipamiento
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">Qué te ofrecemos</Title>
          </Paragraph>
          <Paragraph>
            Somos profesionales con experiencia y por eso tenemos pleno
            conocimiento de las herramientas necesarias para poder brindar un
            servicio de excelencia. Cubrimos todos los niveles de complejidad
            con nuestras unidades equipadas con la última tecnología. Ponemos a
            su alcance a profesionales calificados, equipamiento completo y
            seguro para que su experiencia con nosotros sea de alto nivel.
            <ul>
              <li>Móviles Polivalentes de Alta Complejidad</li>
              <li>Móviles Polivalentes de Baja Complejidad</li>
              <li>
                Móviles para traslados de Alta, Mediana y Baja complejidad
              </li>
              <li>Servicio de Enfermería</li>
              <li>Automóviles para asistencia médica programada</li>
              <li>Unidad Monovalente Pediátrica y Neonatal</li>
            </ul>
          </Paragraph>
          <Spacer height="30px"/>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default Traslados;
