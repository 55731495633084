import React from "react";

import {
  MainGrid,
  MainContent,
  Paragraph,
  Spacer,
  ImageWide,
  TwoColumns,
} from "../../services/common";

import Enfermeria from "./enfermeria.webp";

const EnfermeriaProfesional = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Enfermeria} />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
          <Paragraph>
            El servicio de enfermería fue creado para convertirse en el
            complemento perfecto de nuestros servicios:
            <ul>
              <li>Higiene y confort.</li>
              <li>Cuidados Domiciliarios.</li>
              <li>Inyectables.</li>
              <li>Curaciones.</li>
              <li>Control de signos vitales.</li>
              <li>Colocación y control de sondas y sueros.</li>
              <li>Nebulizaciones ultrasónicas.</li>
            </ul>
            <b>En qué casos necesita contratar un enfermera?</b>
            <ul>
              <li>Si el paciente necesita ser alimentado por sonda.</li>
              <li>Si presenta heridas que necesiten tratamiento.</li>
              <li>Si necesita que se le administre oxígeno.</li>
              <li>Si padece una enfermedad terminal (cuidados paliativos).</li>
              <li>Si el paciente tiene realizada una colostomía.</li>
            </ul>
            <b>Prestaciones Domiciliarias</b>
            <TwoColumns>
              <ul>
                <li>Alimentación por sonda nasogástrica</li>
                <li>Aplicación de calor y frio</li>
                <li>Asistencia a examen médico</li>
                <li>Ayuda en la alimentación oral</li>
                <li>Baño en ducha o en cama (parcial o total)</li>
                <li>Cambio de pañal</li>
                <li>Colocación de bolsa de colostomía</li>
                <li>Colocación de bolsa recolectora de orina</li>
                <li>Colocación de parches</li>
                <li>Colocación de sonda nasogástrica</li>
                <li>Colocación de sonda vesical</li>
                <li>Control acuchek ó Glucosa.</li>
                <li>Control debito de fistulas</li>
                <li>Control de deposiciones</li>
                <li>Control de diuresis</li>
                <li>Control de goteo</li>
                <li>Control de peso</li>
                <li>Control de presión venosa central</li>
                <li>Control de signos vitales</li>
                <li>Control de tensión arterial</li>
                <li>Cuidado de colostomía</li>
                <li>Cuidado de pie diabético</li>
                <li>Curaciones complejas</li>
                <li>Curación de escaras</li>
                <li>Enema de Murphy</li>
                <li>Enema evacuante</li>
              </ul>
              <ul>
                <li>Extracción de sangre</li>
                <li>Lavado de oído</li>
                <li>Lavado de sonda vesical</li>
                <li>Lavajes vaginales con medicamentos</li>
                <li>Medicación intravenosa por goteo</li>
                <li>Medicación tubuladura y/o colateral</li>
                <li>Medicación vía cutánea</li>
                <li>Medicación vía intradérmica</li>
                <li>Medicación vía intramuscular</li>
                <li>Medicación vía intravenosa</li>
                <li>Medicación vía nasal</li>
                <li>Medicación vía oral</li>
                <li>Medicación vía rectal</li>
                <li>Medicación vía subcutánea</li>
                <li>Medicación vía sublingual</li>
                <li>Medicación vaginal</li>
                <li>Nebulizaciones</li>
                <li>Oxigeno por bigotera</li>
                <li>Oxigeno por mascara</li>
                <li>Rasurado higiénico</li>
                <li>Saturometría</li>
                <li>Vendaje elástico</li>
                <li>Vendajes</li>
                <li>Visita domiciliaria</li>
                <li>Administración de nutrición parenteral</li>
                <li>Administración de nutrición enteral</li>
              </ul>
            </TwoColumns>
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default EnfermeriaProfesional;
