import React, { Component } from "react";
import styled from "styled-components";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  TextWide,
  Spacer,
  TwoColumns,
} from "../../services/common";

class Contactenos extends Component {
  constructor(props) {
    super();
    this.state = { nombre: "", email: "", asunto: "", mensaje: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    this.setState({ [fieldName]: fieldValue });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.clearForm();
  }

  clearForm() {
    for (var fieldName in this.state) {
      this.setState({ [fieldName]: "" });
    }
  }

  render() {
    return (
      <>
        <MainGrid backgroundColor={({ theme }) => theme.colors.blueBackground}>
          <MainContent>
            <Spacer height="30px" />
            <TextWide fontSize="35px" fontStyle="normal">
              Contáctenos
            </TextWide>
            <Spacer height="30px" />
          </MainContent>
        </MainGrid>
        <MainGrid
          backgroundColor={({ theme }) => theme.colors.defaultBackground}
        >
          <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
            <Spacer height="20px" />
            <Paragraph>
              <Title fontSize="30px">Envíenos su Consulta</Title>
              <br />
              Le responderemos a la brevedad
            </Paragraph>
            <TwoColumns>
              <Form onSubmit={this.handleSubmit}>
                <input
                  type="text"
                  value={this.state.nombre}
                  name="nombre"
                  onChange={this.handleChange}
                  placeholder="Nombre *"
                />
                <input
                  type="text"
                  value={this.state.email}
                  name="email"
                  onChange={this.handleChange}
                  placeholder="Email *"
                />
                <input
                  type="text"
                  value={this.state.asunto}
                  name="asunto"
                  onChange={this.handleChange}
                  placeholder="Asunto *"
                />
                <textarea
                  type="text"
                  value={this.state.mensaje}
                  name="mensaje"
                  onChange={this.handleChange}
                  placeholder="Mensaje *"
                  rows="6"
                />
                <input type="submit" value="ENVIAR" />
              </Form>
              <Paragraph>
                Llámenos o envíenos su consulta, cuentenos su situación y
                encontraremos la forma más adecuada de brindar salud y
                tranquilidad a su familia.
              </Paragraph>
            </TwoColumns>
            <Spacer height="30px" />
          </MainContent>
        </MainGrid>
      </>
    );
  }
}

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  &:nth-child(n) {
    margin: 5px;
  }

  input[type="text"] {
    padding: 10px;
    margin: 5px 0;
    border-radius: 3px;
    border: 1px solid gray;
    font-family: ${({ theme }) => theme.fonts.paragraphs};
  }

  textarea {
    padding: 10px;
    margin: 5px 0;
    border-radius: 3px;
    border: 1px solid gray;
    font-family: ${({ theme }) => theme.fonts.paragraphs};
  }

  input[type="submit"] {
    padding: 10px 20px;
    margin: 10px 0;
    border-radius: 3px;
    border: 1px solid gray;
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.bright};
    font-family: ${({ theme }) => theme.fonts.paragraphs};
    transition: all 0.5s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGreen};
      box-shadow: 5px 5px 9px -5px rgba(0, 0, 0, 0.75);
    }
  }
`;

export default Contactenos;
