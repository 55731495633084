import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
  TextWide,
} from "../../services/common";

import Equipos from "./equipos.webp";

const EquiposAlquilerVenta = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Equipos} />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.blueBackground}>
        <MainContent>
          <TextWide fontSize="35px" fontStyle="normal">
            El equipamiento, tanto para la internación domiciliaria, como para
            la recuperación, es un elemento más del tratamiento. Le ofrecemos
            alquilar o comprar de acuerdo a sus necesidades, material de
            calidad, indispensable para sus seres queridos.
          </TextWide>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Paragraph>
            <Title>Forma de contratación y entrega</Title>
          </Paragraph>
          <Paragraph>
            Con sólo un llamado telefónico podrá solicitar la entrega de los
            elementos que necesite.
          </Paragraph>
          <Paragraph>
            Se le requerirán los siguientes datos: nombre y apellido, dni,
            domicilio, teléfono, celular y se combinará una franja horaria para
            la entrega.
          </Paragraph>
          <Paragraph>
            Si el domicilio de entrega se encuentra en Capital Federal no tendrá
            costo extra, si se encuentra en Gran Buenos Aires se le informará el
            costo del envío al momento de la contratación.
          </Paragraph>
          <Paragraph>
            En todos los casos el pago del alquiler se realizará en el domicilio
            contra entrega de los elementos solicitados.
          </Paragraph>
          <Paragraph>
            Una vez finalizado el alquiler y previa llamada telefónica se
            realizará el retiro de los elementos por el mismo domicilio sin
            ningún costo
          </Paragraph>
          <Paragraph>
            Este servicio alcanza todas las zonas de Capital y Gran Buenos Aires
            y es nuestro compromiso la entrega de elementos de última generación
            en perfecto estado, limpia y desinfectada, dentro del horario
            acordado durante la recepción del pedido.
          </Paragraph>
          <Paragraph>
            Esperamos que encuentre lo que necesita en nuestro sitio web, y si
            el producto no se encuentra le pedimos se comunique telefónicamente.
          </Paragraph>
          <Paragraph>
            Ofrecemos equipos para:
            <ul>
              <li>Internacion Domiciliaria</li>
              <li>Sillas de rueda</li>
              <li>Andadores</li>
              <li>Material de Ortopedia</li>
              <li>Equipos de oxígeno</li>
              <li>Nebulizadores</li>
              <li>Aspiradores</li>
            </ul>
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default EquiposAlquilerVenta;
