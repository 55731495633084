import React from "react";

import {
  MainGrid,
  MainContent,
  Paragraph,
  Spacer,
  ImageWide
} from "../../services/common";

import Psicologia from "./psicologia.webp";

const TratamientosPsicologicos = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Psicologia} />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
          <Paragraph>
            Concebimos el Área de atención a pacientes, como la oferta de
            iniciar un tratamiento psicológico que acompañe a quien consulta a
            descubrirse o re-descubrirse y, muchas veces, a reconstruir:
            <ul>
              <li>la capacidad de amar, de trabajar y de crear</li>
              <li>la potencia que en cada uno habita</li>
              <li>los lazos gratificantes, la satisfacción lograda</li>
              <li>las decisiones propias.​</li>
            </ul>
            Aliviar los síntomas que se expresan en las diferentes dolencias:
            <ul>
              <li>
                Angustia y ansiedad generalizadas, depresión, irritabilidad.
              </li>
              <li>
                Compulsiones, obsesiones, fobias (ataques de pánico),
                celotipias.
              </li>
              <li>
                Trastornos del sueño y de la alimentación (obesidad, anorexia,
                bulimia).
              </li>
              <li>
                Somatizaciones derivadas del stress (desarreglos
                gastrointestinales, cefaleas, dificultades respiratorias,
                arritmias cardíacas, desregulación de la presión, úlceras,
                enfermedades de la piel, contracturas y otras)
              </li>
              <li>Adicciones (a las sustancias, al juego, al alcohol)</li>
              <li>
                Problemas de relación (familiares, de pareja, laborales) y
                aislamiento.
              </li>
              <li>Orientación vocacional y laboral.</li>
            </ul>
            Entre los niños:
            <ul>
              <li>Problemas de aprendizaje y escolares.</li>
              <li>
                Disfunciones conductuales: agresión, problemas de relación,
                inhibiciones, fobias, déficit atencional (ADD).
              </li>
              <li>
                Retrasos madurativos (TGD), discapacidad, trastornos del
                lenguaje.
              </li>
              <li>Crisis familiares (separación, abandono).</li>
              <li>
                Desarreglos somáticos: enuresis, encopresis, asma, erupciones,
                anginas a repetición y otros.
              </li>
            </ul>
            Sin listas de espera, el turno se obtiene de inmediato.
          </Paragraph>
          <Paragraph>
            El paciente es recibido en la primera entrevista por el colega que
            lo va a continuar atendiendo, salvo que se requiera una derivación.
          </Paragraph>
          <Paragraph>
            Nos dividimos en equipos especializados de acuerdo a cada etapa y
            problemática:
            <ul>
              <li>Niños y Adolescentes</li>
              <li>Adultos y Adultos mayores</li>
              <li>Parejas y Familias</li>
            </ul>
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default TratamientosPsicologicos;
