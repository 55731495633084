import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
} from "../../services/common";

const Contratacion = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
          <Paragraph>
            <Title fontSize="40px">
              Ofrecemos soluciones, adaptadas a tus necesidades.
            </Title>
          </Paragraph>
          <Paragraph>
            <b>NIVELES DE CUIDADOS DOMICILIARIOS</b> <br />
            Dentro del Servicio de Cuidados Domiciliarios existen distintos
            niveles según la necesidad del paciente, que implican una variación
            progresiva de las prestaciones requeridas
          </Paragraph>
          <Paragraph>
            <b>a) Internación Domiciliaria</b> <br />
            Proporcionamos cuidados permanentes del paciente en su hogar, de
            acuerdo a su patología y complejidad de tratamiento, reemplazando o
            complementando la internación tradicional a un costo menor. <br />
            La modalidad consiste en llevar al domicilio del paciente todos los
            recursos materiales y humanos necesarios para su correcta atención:
            médicos clínicos y especialistas, enfermeros, oxigenoterapia, camas
            ortopédicas, equipamiento de alta y baja complejidad, materiales
            descartables, medicamentos y todo lo que resulte necesario.
          </Paragraph>
          <Paragraph>
            <b>b) Asistencia Domiciliaria</b> <br />
            Atendemos al paciente en su domicilio de acuerdo a las indicaciones
            médicas, elaborando un programa asistencial a medida de la necesidad
            para la realización de tareas específicas y oportunas, combinando
            prestaciones de enfermería con visitas médicas programadas y todos
            servicios necesarios.
          </Paragraph>
          <Paragraph>
            Diálisis Peritoneal, este servicio consta de la provisión de
            equipamiento, soluciones y profesionales en el domicilio del
            paciente. <br />
            La Diálisis Peritoneal (DP) está basada en el mismo proceso de
            filtración que la hemodiálisis, pero en lugar de usar un riñón
            artificial como filtro se utiliza la membrana peritoneal. <br />
            Existen dos tipos principales de Diálisis Peritoneal: Diálisis
            Peritoneal Continua Ambulatoria (DPCA) y Diálisis Peritoneal Cíclica
            Continua (DPCC). Ambas se realizan en el domicilio del paciente.
          </Paragraph>
          <Paragraph>
            El médico o la enfermera controlarán de forma rutinaria las
            condiciones físicas del paciente, su dieta y la medicación. Los
            cambios en la pauta de tratamiento como el número de intercambios,
            la cantidad de solución, el tiempo de permanencia y el tiempo entre
            los intercambios se ajustan a las necesidades del paciente.
          </Paragraph>
          <Paragraph>
            <b>c) Seguimiento Domiciliario</b> <br />
            Servicio de Médicos de Cabecera a domicilio, consistente en la
            programación de visitas semanales, quincenales o mensuales, en
            función de las necesidades, para dar seguimiento al cuadro clínico
            del paciente, controlar y evaluar su estado de salud general.
          </Paragraph>
          <Paragraph>
            <b>d) Cuidadores Domiciliarios</b> <br />
            Este programa está orientado principalmente a adultos mayores, con
            el objetivo de mejorar su calidad de vida y fomentar su autonomía
            facilitando el desarrollo de las tareas cotidianas. Comprende los
            servicios de Cuidadores Domiciliarios y Asistentes.
          </Paragraph>
          <Paragraph>
            Prestaciones de Cuidados Domiciliarios:
            <ul>
              <li>Coordinación Médica.</li>
              <li>Servicio de Enfermera a Domicilio durante las 24 horas.</li>
              <li>
                Seguimiento de patologías por profesionales especializados.
              </li>
              <li>Interconsultas con especialistas.</li>
              <li>
                Oxigenoterapia (concentradores de oxígeno, aspiradores,
                nebulizadores, respiradores, etc).
              </li>
              <li>
                Radiología, Laboratorio, ECG y Ecocardiograma a Domicilio.
              </li>
              <li>
                Equipamiento Sanitario (bombas de infusión, camas ortopédicas,
                sillas de ruedas, etc).
              </li>
              <li>Provisión de material descartable y medicamentos.</li>
              <li>Contención especializada para el paciente y su familia.</li>
              <li>Servicio de emergencias médicas y traslados.</li>
              <li>Asistencia con médicos de cabecera.</li>
              <li>Atención integral especializada al paciente.</li>
              <li>Cuidados médicos de alta complejidad.</li>
              <li>Rehabilitación de pacientes.</li>
              <li>Soporte Nutricional.</li>
            </ul>
          </Paragraph>
          <Paragraph>
            <Title>Forma de contratación:</Title>
            <ul>
              <li>Por Hora</li>
              <li>Por día</li>
              <li>De Lunes a Viernes</li>
              <li>De Lunes a Sábado</li>
              <li>De Lunes a Domingo</li>
              <li>Por Quincena</li>
              <li>Por Mes</li>
            </ul>
            En caso que usted requiera cuidados de tiempo completo organizamos
            turnos de asistencia para que el paciente nunca se encuentre solo.
          </Paragraph>
          <Paragraph>
            Tambien puede requerir determinadas horas diarias, o semanales o
            contratar personal para situaciones puntuales, como cubrir las
            vacaciones de un familiar o acompañar al paciente a una terapia.
          </Paragraph>
          <Paragraph>
            Para esto podemos armar módulos horarios según su conveniencia:
            <ul>
              <li>24 horas al día, todo el mes</li>
              <li>12 horas al día, todo el mes</li>
              <li>10 horas al día, todo el mes</li>
              <li>9 horas al día, todo el mes</li>
              <li>8 horas al día, todo el mes</li>
              <li>7 horas al día, todo el mes</li>
              <li>6 horas al día, todo el mes</li>
              <li>4 horas al día, todo el mes</li>
              <li>Prestaciónes por hora y por mes</li>
            </ul>
          </Paragraph>
          <Spacer height="30px"/>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default Contratacion;
